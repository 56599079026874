import React from 'react'
import {
  Paper,
  Typography,
  Button,
  LinearProgress,
  Grid
} from '@mui/material'
import TodayIcon from '@mui/icons-material/Today'
import MapIcon from '@mui/icons-material/Room'
import InstagramIcon from '@mui/icons-material/Instagram'
import TelegramIcon from '@mui/icons-material/Telegram'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import { Trans } from 'react-i18next'
import { withStyles } from '@mui/styles'
import { Snack, ModalCust, WhatsAppFloater, LngSelect } from './Lib'
import {
  styleGuide,
  gridItem,
  gridSpacer,
  paperStyle,
  imgHome,
  homeHeadlineSpacer,
  homeSpacer,
  homeText,
  whatsappChat,
  homeBg
} from './Style'
import __ from '../util'
import i18n from '../i18n'

class Home extends React.Component {
  constructor (props) {
    super(props)
    this.cx = props.cx
    this.reset = {
      isLoading: false,
      inputDisabled: false
    }
    this.state = {
      ...this.reset
    }
    this.handleMaps = this.handleMaps.bind(this)
    this.handleAppointment = this.handleAppointment.bind(this)
    this.handleIg = this.handleIg.bind(this)
    this.handleTg = this.handleTg.bind(this)
    this.handleWhatsappChat = this.handleWhatsappChat.bind(this)
  }

  handleMaps () {
    window.open(__.cfg('mapsUrl'), '_blank')
  }

  handleAppointment () {
    window.open(__.cfg('calendlyUrl'), '_blank')
  }

  handleIg () {
    window.open(__.cfg('instagramUrl'), '_blank')
  }

  handleTg () {
    window.open(__.cfg('telegramUrl'), '_blank')
  }

  handleWhatsappChat () {
    window.open(__.cfg('whatsappUrl'), '_blank')
  }

  async componentDidMount () {
    // set body bg
    document.body.style.backgroundColor = styleGuide.backgroundDark

    Object.assign(this, __.initView(this, 'home'))
  }

  render () {
    if (this.state.err) {
      return (
        <ModalCust>
          {this.state.err}
        </ModalCust>
      )
    } else if (this.state.isLoading) {
      return (<LinearProgress />)
    } else {
      return (
        <div>
          {this.state.snack && (
            <Snack
              msg={this.state.snack}
              onClose={() => this.setState({ snack: null })}
            />
          )}
          <Paper square className={this.props.classes.homeBg} elevation={0}>
            <Grid container spacing={0} justifyContent='center'>
              <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
                <LngSelect
                  value={i18n.resolvedLanguage}
                  onChange={(event) => {
                    i18n.changeLanguage(event.target.value)
                    this.setState({newLang: event.target.value})
                    document.documentElement.lang = event.target.value
                  }}
                />
                <img
                  height='130px'
                  width='130px'
                  src='//qrcdn.gardenbarcelona.es/img/higlogo-trans.png'
                  title='Logo Garden CSC'
                  alt='Logo Garden CSC'
                  style={{margin: '0 auto', display:'block', marginBottom: 60}}
                />
                <Typography variant='h1' align='center' gutterBottom style={{fontFamily: 'sutro',  marginBottom: 60}}>
                  <Trans i18nKey='home.name'>Garden Social Club</Trans>
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent='center'>
              <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
                <Typography variant='body1' className={this.props.classes.homeText}>
                  <Trans i18nKey='home.intro'>Garden Barcelona is an exclusive Cannabis Social Club founded in cooperation with the German company Hanf im Glück, and is located in the heart of Barcelona's Eixample district. It’s central location allows you to easily reach it by public transport, car or on foot. Garden Barcelona delights it’s members not only with an attractive and thoughtful interior design, but also with interesting professional meetings, handpicked music and magnificent social events. Our members receive excellent advice and treatment, thanks to a professionally trained team, who guide them through an exceptional and high-quality menu, making it easier for all our guests to feel fully at ease at our Cannabis Social Club.</Trans>
                </Typography>
                <br />
                <Button
                  onClick={this.handleAppointment}
                  style={{marginBottom: 20}}
                  startIcon={<TodayIcon />}
                  variant='outlined'
                  fullWidth
                >
                  <Trans i18nKey='btns.appointment'>Book appointment</Trans>
                </Button>
                <Button
                  onClick={this.handleMaps}
                  style={{marginBottom: 20}}
                  startIcon={<MapIcon />}
                  variant='outlined'
                  fullWidth
                >
                  <Trans i18nKey='btns.maps'>Find on Maps</Trans>
                </Button>
                <Button
                  onClick={this.handleWhatsappChat}
                  className={this.props.classes.whatsappChat}
                  startIcon={<WhatsAppIcon />}
                  variant='outlined'
                  fullWidth
                >
                  <Trans i18nKey='btns.whatsappChat'>Chat on WhatsApp</Trans>
                </Button>
              </Grid>
              <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
                <img loading='lazy' src='//qrcdn.gardenbarcelona.es/img/Garden-CSC-Lounge-3.jpg' alt='Lounge' className={this.props.classes.imgHome}/>
              </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent='center' className={this.props.classes.homeSpacer}>
              <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
                <img loading='lazy' src='//qrcdn.gardenbarcelona.es/img/Garden-CSC-What-we-do.jpg' alt='Lounge' className={this.props.classes.imgHome}/>
              </Grid>
              <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
                <Typography variant='h2' className={this.props.classes.homeHeadlineSpacer} gutterBottom>
                  <Trans i18nKey='home.hlWhat'>What we do</Trans>
                </Typography>
                <Typography variant='body1' className={this.props.classes.homeText}>
                  <Trans i18nKey='home.what'>We believe that cannabis can improve people's quality of life in a variety of ways. Therefore, we consider it our mission to contribute to the responsible use of cannabis through guided access to hand-picked premium products and consistent education. At the same time, we at Garden Social Club also want to make the countless possibilities of the cannabis plant as a resource, stimulant, medicine and economic driver recognizable, in order to ensure a change in the mindset of the masses. The direct feedback of our members forms the basis of our work, which we try to optimize continuously.</Trans>
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent='center' className={this.props.classes.homeSpacer}>
              <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
                <Typography variant='h2' className={this.props.classes.homeHeadlineSpacer} gutterBottom>
                  <Trans i18nKey='home.hlWhatWeOffer'>What we offer</Trans>
                </Typography>
                <Typography variant='body1' className={this.props.classes.homeText}>
                  <Trans i18nKey='home.whatWeOffer'>Cannabis is our passion and we want to share it with our members. Therefore, we focus on the highest quality in all areas of Garden Cannabis Social Club. This applies both to the design of our high-quality assortment, which stand out due to their freshness, variance and exclusivity, as well as to the professional service of our team, which is to provide detailed and individual information about products, ingredients, forms of consumption and possible effects of THC-containing products. We want to become not only the Cannabis Social Club of trust for our members, but also empower them through the transfer of expertise to make their own contribution to more education, safety and self-determination in connection with cannabis.</Trans>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
                <img loading='lazy' src='//qrcdn.gardenbarcelona.es/img/Garden-CSC-What-we-offer.jpg' alt='Lounge' className={this.props.classes.imgHome}/>
              </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent='center' className={this.props.classes.homeSpacer}>
              <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
                <img loading='lazy' src='//qrcdn.gardenbarcelona.es/img/Garden-CSC-Desk.jpg' alt='Lounge' className={this.props.classes.imgHome}/>
              </Grid>
              <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
                <Typography variant='h2' className={this.props.classes.homeHeadlineSpacer} gutterBottom>
                  <Trans i18nKey='home.hlFacilities'>Facilities</Trans>
                </Typography>
                <Typography variant='body1' className={this.props.classes.homeText}>
                  <Trans i18nKey='home.facilities'>Our members deserve only the best. Therefore, we think that our service-oriented consultation on high-quality cannabis products should also take place in a first-class ambience. To meet this demand, we have put a lot of emphasis on the conception of an appealing and thoughtful interior design. The result of these efforts is a social club that not only adapts to the occasion and atmosphere of the day through the selection of lighting and music, but can also simply meet every need of its members; whether it's a co-working session with colleagues, a game night with friends, a billiards tournament with new acquaintances, or a technical lecture in the company of interested club members - our club always caters to you!</Trans>
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent='center' className={this.props.classes.homeSpacer}>
              <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
                <Typography variant='h2' className={this.props.classes.homeHeadlineSpacer} gutterBottom>
                  <Trans i18nKey='home.hlMusic'>Music & Events</Trans>
                </Typography>
                <Typography variant='body1' className={this.props.classes.homeText}>
                  <Trans i18nKey='home.music'>Garden Social Club is a place to meet - because in our opinion, the social aspect of a cannabis club should be just as important as a pleasant atmosphere or professional advice on exclusive products. That’s why we offer our members a variety of well thought-out events that focus not only on cannabis but also on music, art, fashion and well-being. Through carefully selected collaborations with artists, brands and professionals, we try to provide each event with added value for our members. With the help of a diverse range of live music, entertainment and group activities, we aim not only to appeal to the different tastes of our guests, but also to unite and expand them under the common denominator of cannabis.</Trans>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
                <img loading='lazy' src='//qrcdn.gardenbarcelona.es/img/Garden-CSC-Event.jpg' alt='Lounge' className={this.props.classes.imgHome}/>
              </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent='center' className={this.props.classes.homeSpacer}>
              <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
                <img loading='lazy' src='//qrcdn.gardenbarcelona.es/img/Garden-CSC-Entry-Door.jpg' alt='Lounge' className={this.props.classes.imgHome}/>
              </Grid>
              <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
                <Typography variant='h2' className={this.props.classes.homeHeadlineSpacer} gutterBottom>
                  <Trans i18nKey='home.hlLocation'>Location</Trans>
                </Typography>
                <Typography variant='body1' className={this.props.classes.homeText}>
                  <Trans i18nKey='home.location'>The central location in the heart of the beautiful Eixample district is undoubtedly one of Garden Cannabis Social Club’s greatest assets. Close to the well-known Pg. de Sant Joan and right between the Verdaguer and Girona metro stations, Garden CSC is easy to reach while also introducing it’s members to one of the most beautiful neighborhoods in the city of Barcelona.</Trans>
                </Typography>
                <br />
                <Typography variant='body1' className={this.props.classes.homeText}>
                  <Trans i18nKey='home.time'>Our Cannabis Social Club is open all year round and every day of the week. Therefore the Club’s premises are available to our members every day between 5 p.m. and midnight.</Trans>
                </Typography>
                <br />
                <Button
                  onClick={this.handleMaps}
                  style={{marginBottom: 20}}
                  startIcon={<MapIcon />}
                  variant='outlined'
                  fullWidth
                >
                  <Trans i18nKey='btns.maps'>Find on Maps</Trans>
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent='center'>
              <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
                <Button
                  fullWidth
                  onClick={this.handleIg}
                  startIcon={<InstagramIcon />}
                  style={{marginTop: 20, marginBottom: 20}}
                  >
                    <Trans i18nKey='btns.instagram'>Contact on Instagram</Trans>
                  </Button>
                <Button
                  fullWidth
                  onClick={this.handleTg}
                  startIcon={<TelegramIcon />}
                  style={{marginBottom: 40}}
                  >
                    <Trans i18nKey='btns.telegram'>Contact on Telegram</Trans>
                  </Button>
                  <Typography variant='body2' align='center'>
                    Garden CSC Social Club
                    C/ de Bailèn, 110, 08009 Barcelona, Spain
                  </Typography>
              </Grid>
            </Grid>
          </Paper>
          <WhatsAppFloater />
        </div>
      )
    }
  }
}

export default
  withStyles({
    gridItem,
    gridSpacer,
    paperStyle,
    imgHome,
    homeHeadlineSpacer,
    homeSpacer,
    homeText,
    whatsappChat,
    homeBg
  })(Home)
