import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: 'en',
    whitelist: ['de', 'es', 'fr', 'it', 'cat', 'en'],
    supportedLngs: ['de', 'es', 'fr', 'it', 'cat', 'en'],
    lowerCaseLng: true,
    checkWhitelist: true,
    load: 'languageOnly', // do not try to load fr-FR or en-US
    detection: {
      order: ['querystring', 'path', 'localStorage', 'sessionStorage', 'navigator'],
      checkWhitelist: true
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      wait: true
    },
    resources: {
      en: {
        translation: {
          seo: {
            title: '▷ Cannabis Social Club Barcelona | Eixample CSC',
            meta: 'Garden Barcelona, the exclusive ✓Cannabis Social Club in the center of Barcelona. Design interior, good ♫music & ☀social events. ➔Join us!'
          },
          btns: {
            appointment: 'Book appointment',
            maps: 'Find on Maps',
            instagram: 'Contact on Instagram',
            telegram: 'Contact on Telegram',
            whatsappChat: 'Chat on Whatsapp'
          },
          home: {
            name: 'Garden Social Club',
            intro: 'Garden Barcelona is an exclusive Cannabis Social Club founded in cooperation with the German company Hanf im Glück, and is located in the heart of Barcelona\'s Eixample district. It’s central location allows you to easily reach it by public transport, car or on foot.\n\nGarden Barcelona delights it’s members not only with an attractive and thoughtful interior design, but also with interesting professional meetings, handpicked music and magnificent social events. Our members receive excellent advice and treatment, thanks to a professionally trained team, who guide them through an exceptional and high-quality menu, making it easier for all our guests to feel fully at ease at our Cannabis Social Club.',
            hlWhat: 'What we do',
            what: 'We believe that cannabis can improve people\'s quality of life in a variety of ways. Therefore, we consider it our mission to contribute to the responsible use of cannabis through guided access to hand-picked premium products and consistent education.\n\nAt the same time, we at Garden Social Club also want to make the countless possibilities of the cannabis plant as a resource, stimulant, medicine and economic driver recognizable, in order to ensure a change in the mindset of the masses.\n\nThe direct feedback of our members forms the basis of our work, which we try to optimize continuously.',
            hlWhatWeOffer: 'What we offer',
            whatWeOffer: 'Cannabis is our passion and we want to share it with our members. Therefore, we focus on the highest quality in all areas of Garden Cannabis Social Club. This applies both to the design of our high-quality assortment, which stand out due to their freshness, variance and exclusivity, as well as to the professional service of our team, which is to provide detailed and individual information about products, ingredients, forms of consumption and possible effects of THC-containing products.\n\nWe want to become not only the Cannabis Social Club of trust for our members, but also empower them through the transfer of expertise to make their own contribution to more education, safety and self-determination in connection with cannabis.',
            hlFacilities: 'Facilities',
            facilities: 'Our members deserve only the best. Therefore, we think that our service-oriented consultation on high-quality cannabis products should also take place in a first-class ambience. To meet this demand, we have put a lot of emphasis on the conception of an appealing and thoughtful interior design.\n\nThe result of these efforts is a social club that not only adapts to the occasion and atmosphere of the day through the selection of lighting and music, but can also simply meet every need of its members; whether it\'s a co-working session with colleagues, a game night with friends, a billiards tournament with new acquaintances, or a technical lecture in the company of interested club members - our club always caters to you!',
            hlMusic: 'Music & Events',
            music: 'Garden Social Club is a place to meet - because in our opinion, the social aspect of a cannabis club should be just as important as a pleasant atmosphere or professional advice on exclusive products.\n\nThat’s why we offer our members a variety of well thought-out events that focus not only on cannabis but also on music, art, fashion and well-being.\n\nThrough carefully selected collaborations with artists, brands and professionals, we try to provide each event with added value for our members. With the help of a diverse range of live music, entertainment and group activities, we aim not only to appeal to the different tastes of our guests, but also to unite and expand them under the common denominator of cannabis.',
            hlLocation: 'Location',
            location: 'The central location in the heart of the beautiful Eixample district is undoubtedly one of Garden Cannabis Social Club’s greatest assets. Close to the well-known Pg. de Sant Joan and right between the Verdaguer and Girona metro stations, Garden CSC is easy to reach while also introducing it’s members to one of the most beautiful neighborhoods in the city of Barcelona.',
            time: 'Our Cannabis Social Club is open all year round and every day of the week. Therefore the Club’s premises are available to our members every day between 5 p.m. and midnight.'
          },
          memberCreate: {
            title1: 'Welcome to',
            title2: 'Garden CSC',
            titleExplain: 'Please fill new member details',
            firstName: 'First Name',
            lastName: 'Last Name',
            address: 'Address',
            addressHelper: 'Street + Number, Floor / Apt, Zip, City, Country',
            ref: 'Referred by',
            refHelper: 'The name of the existing member who referred you',
            birthday: 'Birthday',
            docType: 'Document type',
            docType1: 'Passport',
            docType2: 'ID-Card',
            docType3: 'Drivers-Licence',
            docNumber: 'Document number',
            monthlyG: 'Monthly estimate of consumption in gramm',
            monthlyGHelper: 'Between 1 and 400',
            faceImage: 'Face Image',
            startCam: 'Start Cam',
            signature: 'Signature',
            clearSig: 'Clear',
            confirmSig: 'Confirm Signature',
            memberquest: 'Member Questions',
            resident: 'I am a resident in Spain',
            chooseOne: 'Choose one',
            habitual: 'I am an habitual cannabis user',
            medical: 'I am a medical cannabis patient',
            tos: 'Terms & Conditions',
            showTos: 'Show TOS (AS. MILENA VERDA - Solicitud de ingreso de socio)',
            tosChecked: 'I have read and I accept the terms and conditions. I want to become a member.',
            createMember: 'Create Member',
            createdLbl: 'New member created ✅',
            regThankyou: 'Thank you for registering. You are ready to check-in, please talk to our staff.',
            regPersonalId: 'Your personal member ID:',
            regPersonalQR: 'Your personal QR code:',
            regPersonalQRExplain: 'The QR code was also sent to your email address. Keep this safe so that you can easily check in next time or take a screenshot now.',
            continueMsg: 'To continue please ensure all fields are complete',
            emailMissing: 'Email missing',
            fistNameMissing: 'First name missing',
            lastNameMissing: 'Last name missing',
            addressMissing: 'Address missing',
            docNumberMissing: 'Document number missing',
            docMissing: 'Document type missing',
            monthlyCons: 'Monthly consumption missing',
            acceptTos: 'Accept terms & conditions',
            signatureMissing: 'Signature missing',
            faceImageMissing: 'Face image missing'
          }
        }
      },
      it: {
        translation: {
          seo: {
            title: '▷ Cannabis Social Club Barcelona | Eixample CSC',
            meta: 'Garden Barcelona, l\'esclusivo ✓Cannabis Social Club nel centro di Barcellona. Interni di design, buona ♫musica e ☀eventi sociali. ➔Diventa membro!'
          },
          btns: {
            appointment: 'Prenota appuntamento',
            maps: 'Trova su Maps',
            instagram: 'Contatta su Instagram',
            telegram: 'Contatta su Telegram',
            whatsappChat: 'Chatta su Whatsapp'
          },
          home: {
            name: 'Garden Social Club',
            intro: 'Garden Barcelona è un Cannabis Social Club esclusivo, fondato in collaborazione con la societá tedesca Hanf im Glück e si trova nel cuore del quartiere Eixample di Barcellona. Grazie alla sua posizione centrale è facilmente raggiungibile sia con i mezzi pubblici che in auto o a piedi.\n\nGarden Barcelona attrae i suoi membri non solo con un design d´interni accattivante e ben pensato, ma offre anche conferenze interessanti e magnifici eventi. Il team professionale e adeguamente formato, accompagna i membri con piacere, prendendosi il tempo per spiegare il menú esclusivo e di alta qualità, rendendo per gli ospiti piú semplice sentirsi completamente a proprio agio.',
            hlWhat: 'Casa facciamo',
            what: 'Crediamo che la Cannabis possa migliorare la qualità  della vita delle persone in vari modi. Pertanto consideriamo la nostra missione, il contribuire all´uso responsabile della Cannabis attraverso il sostegno e l´accesso di ottimi prodotti accuratamente selezionati e una costante educazione. Allo stesso tempo noi di Garden Social Club vogliamo mostrare le innumerevoli proprietà della pianta di Cannabis come risorsa, un mezzo di piacere, medicina e come motore economico, al fine di garantire un cambiamento nella mentalità di massa.\n\nIl feedback diretto dei nostri membri costituisce la base del nostro lavoro, che cerchiamo di ottimizzare continuamente.',
            hlWhatWeOffer: 'Cosa offriamo',
            whatWeOffer: 'La Cannabis è  la nostra passione e vogliamo condividerla con i nostri membri. Pertanto, ci concentriamo sulla massima qualità in tutte le aree del Garden Cannabis Social Club. Ciò viene applicato sia alla progettazione del nostro assortimento di alta qualità, che si distingue per la sua freschezza, varietà ed esclusività, sia al servizio professionale del nostro team, che fornisce informazioni dettagliate e individuali sui prodotti, ingredienti, sulla modalità di consumo e sui possibili effetti dei prodotti contenenti THC.\n\nVogliamo non solo diventare il Cannabis Social Club di fiducia per i nostri membri, ma anche  responsabilizzare i nostri membri fornendo loro le competenze necessarie per dare un contributo ad una maggiore educazione, sicurezza e autonomia in relazione alla cannabis.',
            hlFacilities: 'Arredamento',
            facilities: 'I nostri membri meritano solo il meglio. Pertanto, riteniamo che la nostra consulenza sui prodotti di alta qualità dovrebbe svolgersi in un ambiente di prima classe. Per soddisfare questa richiesta, abbiamo posto molta importanza sul concepimento di un design d´interni accurato e adatto alle vostre esigenze.\n\nIl risultato di questi sforzi è un Social Club che attraverso la scelta della luce e della musica, non solo si adatta all´occasione e all´atmosfera del giorno, ma riesce a soddisfare ogni esigenza dei suoi membri, che si tratti di una sessione di co-working con i colleghi, di una serata di giochi con gli amici, di un torneo di biliardo facendo nuove conoscenze o conferenze per i membri interessati – il nostro Club è sempre a vostra disposizione!',
            hlMusic: 'Musica & Eventi',
            music: 'Garden Social Club è un luogo d´incontro, perché a nostro avviso, l´aspetto sociale di un Cannabis Club dovrebbe essere tanto importante quanto un´atmosfera piacevole o una consulenza professionale sui prodotti. Ecco perché offriamo ai nostri membri una varietà di eventi che si concentrano, oltre che sulla Cannabis anche sulla musica, arte, musica e benessere.\n\nAttraverso collaborazioni accuratamente scelte con artisti, marchi e professionisti, cerchiamo di valorizzare maggiormente ogni evento. Con l´aiuto di una vasta gamma di musica dal vivo, intrattenimento e attività di gruppo, miriamo non solo ad attirare i diversi gusti dei nostri ospiti, ma anche ad unirli ed espanderli sotto il comune denominatore, che in questo caso è la Cannabis.',
            hlLocation: 'Posizione',
            location: 'La posizione centrale nel cuore del magnifico quartiere Eixample è senza dubbio uno dei maggiori pregi del Garden Cannabis Social Club. Vicino al noto Pg. De Sant Joan e tra le stazioni della metropolitana Verdaguer e Girona, il Garden CSC è facilmente raggiungibile e allo stesso tempo introduce i suoi membri in uno dei quartieri più belli della città di Barcellona.',
            time: 'Il nostro Cannabis Social Club è aperto tutto l´anno e tutti i giorni della settimana. Pertanto, i locali del Club a disposizione dei nostri membri ogni giorno dalle 17.00 a mezzanotte.',
          },
          memberCreate: {
            title1: 'Benvenuto nel',
            title2: 'Garden CSC',
            titleExplain: 'Inserire i dati del nuovo membro',
            firstName: 'Nome',
            lastName: 'Cognome',
            address: 'Indirizzo',
            addressHelper: 'Via, numero, piano, porta / CAP, città',
            ref: 'Presentato da',
            refHelper: 'Il nome del membro esistente che ti ha presentato',
            birthday: 'Data di nascita',
            docType: 'Tipo di documento',
            docType1: 'Passaporto',
            docType2: 'Carta d\'identità',
            docType3: 'Patente di guida',
            docNumber: 'Numero del documento',
            monthlyG: 'Stima mensile del consumo in grammi',
            monthlyGHelper: 'Tra 1 e 400',
            faceImage: 'Selfie',
            startCam: 'Avviare la fotocamera',
            signature: 'Firma',
            clearSig: 'Annullare',
            confirmSig: 'Confermare la firma',
            memberquest: 'Domande sul membro',
            resident: 'Sono residente in Spagna',
            chooseOne: 'Scegliere una delle opzioni',
            habitual: 'Sono un consumatore abituale di cannabis',
            medical: 'Sono un paziente di cannabis terapeutica',
            tos: 'Termini e condizioni',
            showTos: 'Mostrare i TeC (AS. MILENA VERDA - Solicitud de ingreso de socio)',
            tosChecked: 'Ho letto e accetto i termini e condizioni. Voglio diventare un membro.',
            createMember: 'Creare un membro',
            createdLbl: 'Nuovo membro creato ✅',
            regThankyou: 'Grazie per esserti registrato. Sei pronto per il check-in, parla con il nostro staff.',
            regPersonalId: 'Il tuo ID personale:',
            regPersonalQR: 'l vostro codice QR:',
            regPersonalQRExplain: 'Il codice QR è stato inviato anche al vostro indirizzo e-mail. Salvatelo o fate uno screenshot per poter effettuare facilmente il check-in la prossima volta',
            continueMsg: 'Per continuare, accertarsi che tutti i campi siano compilati',
            emailMissing: 'Manca l\'indirizzo e-mail',
            fistNameMissing: 'Manca il nome',
            lastNameMissing: 'Manca il cognome',
            addressMissing: 'Manca l\'indirizzo',
            docNumberMissing: 'Manca il numero del documento',
            docMissing: 'Manca il tipo di documento',
            monthlyCons: 'Manca il consumo mensile',
            acceptTos: 'Accettare i termini e condizioni',
            signatureMissing: 'Manca la firma',
            faceImageMissing: 'Manca il selfie'   
          }
        }
      },
      fr: {
        translation: {
          seo: {
            title: '▷ Cannabis Social Club Barcelona | Eixample CSC',
            meta: 'Garden Barcelona, l\'exclusif ✓Cannabis Social Club dans le centre de Barcelone. Beaux intérieurs, bonne ♫musique et ☀événements. ➔Rejoignez-nous!'
          },
          btns: {
            appointment: 'Prendre rendez-vous',
            maps: 'Trouver sur Maps',
            instagram: 'Contacter sur Instagram',
            telegram: 'Contacter sur Telegram',
            whatsappChat: 'Discuter sur Whatsapp'
          },
          home: {
            name: 'Garden Social Club',
            intro: "Garden Barcelona est un Cannabis Social Club exclusif fondé en coopération avec l'entreprise allemande Hanf im Glück et situé au cœur du quartier de l’Eixample à Barcelone. Sa centralité le rend facilement accessible par les transports publics, en voiture ou à pied.\n\nGarden Barcelona ravit ses membres non seulement par son design intérieur attrayant et soingé, mais aussi par des réunions professionnelles intéressantes, de la musique sélectionnée et de grands événements sociaux. Nos membres reçoivent d'excellents conseils et traitements râce à une équipe professionnelle qui les guide à travers un menu exceptionnel et de haute qualité, permettant à tous nos invités de se sentir très à l'aise et accompangés.",
            hlWhat: 'Ce que nous faisons',
            what: "Nous sommes convaincus que le cannabis peut améliorer la qualité de vie des gens à bien des égards. C'est pourquoi nous considérons qu'il est de notre devoir de contribuer à son utilisation responsable par le biais d'une éducation cohérente et d'un accès guidé à des produits de qualité supérieure soigneusement sélectionnés.\n\nEn même temps, nous voulons également, au sein du Garden Social Club, rendre les innombrables possibilités de la plante de cannabis en tant que ressource, stimulant, médicament et moteur économique reconnaissables, afin de garantir un changement d'état d'esprit des masses.\n\nEn même temps, au Garden Social Club, nous voulons aussi faire reconnaître l'immense potentiel de cette plante en tant que ressource, stimulant, médicament et moteur économique, dans le but d'amener un changement dans la mentalité des masses. Les réactions que nous recevons directement de nos membres constituent la base de notre travail, que nous essayons d'optimiser chaque jour.",
            hlWhatWeOffer: 'Ce que nous offrons',
            whatWeOffer: "Le cannabis est notre passion et nous voulons la partager avec nos membres. Nous nous engageons alors à offrir la plus haute qualité dans tous les domaines de Garden Cannabis Social Club. Cela s'applique aussi bien à l'approche de notre assortiment de haute qualité, qui se distingue par sa fraîcheur, sa variété et son exclusivité, qu'au service de conseil de notre équipe, qui  vise à fournir des informations détaillées et individuelles sur les produits, les ingrédients, les formes de consommation et les effets possibles des produits contenant du THC.\n\nNous voulons non seulement devenir le Cannabis Social Club de confiance pour nos membres, mais aussi, par la transmission des connaissances, encourager leur propre contribution à l'éducation, à la sécurité et à l'autodétermination en ce qui concerne le cannabis.",
            hlFacilities: "Installations",
            facilities: "Nos membres méritent que le meilleur: nous considérons que notre service orienté vers le conseil en matière de produits de cannabis de haute qualité doit également se dérouler dans un environnement de premier ordre. Pour atteindre cet objectif, nous avons mis l'accent sur la conception d'un design intérieur attrayant et réfléchi.\n\nLe résultat de ces efforts est un club social qui s'adapte aux différentes occasions et moments de la journée grâce à une sélection minutieuse de l'éclairage et de la musique, et qui est capable de répondre à tous les besoins de ses membres : qu'il s'agisse d'une session de travail avec des collègues, d'un après-midi de jeu avec des amis, d'un tournoi de billard avec de nouvelles connaissances ou d'une discussion spécialisée en compagnie de membres intéressés, notre club est toujours à votre disposition!",
            hlMusic: "Musique et événements",
            music: "Garden Social Club est un lieu de rencontre - car à notre avis, l'aspect social d'un club de cannabis devrait être aussi important qu'une atmosphère agréable ou des conseils professionnels sur des produits exclusifs. C'est pourquoi nous offrons à nos membres une variété d'événements originaux qui, en plus du cannabis, se concentrent également sur la musique, l'art, la mode ou le bien-être en fonction de la soirée.\n\nGrâce à des collaborations choisies avec des artistes, des marques et des professionnels, nous essayons d'apporter une valeur ajoutée à nos membres lors de chaque événement. À travers une offre variée de musique live, de divertissements et d'activités de groupe, nous visons non seulement à satisfaire les différents goûts de nos invités, mais aussi à les unir et à les élargir sous le dénominateur commun qu'est le cannabis.",
            hlLocation: "Emplacement",
            location: "Sa situation centrale au cœur du magnifique quartier de l'Eixample est sans aucun doute l'un des plus grands atouts du Garden Cannabis Social Club. Proche du célèbre Pg. de Sant Joan et juste entre les stations de métro Verdaguer et Girona, le Garden est facilement accessible tout en faisant découvrir à ses membres l'un des plus beaux quartiers de la ville de Barcelone.",
            time: "Notre club est ouvert toute l'année et tous les jours de la semaine. Nos installations sont alors à la disposition des membres tous les jours de 17 h 00 à 00 h 00."
          },
          memberCreate: {
            title1: 'Bienvenue au',
            title2: 'Garden CSC',
            titleExplain: 'Remplissez les données du nouveau membre',
            firstName: 'Prénom',
            lastName: 'Nom',
            address: 'Adresse',
            addressHelper: 'Rue, numéro, étage, appt / code postal, ville',
            ref: 'Membre garant',
            refHelper: 'Nom du membre garant',
            birthday: 'Anniversaire',
            docType: 'Sorte de document',
            docType1: 'Passeport',
            docType2: 'Carte d\'identité',
            docType3: 'Permis de conduire',
            docNumber: 'Numéro du document',
            monthlyG: 'Estimation mensuelle de consommation en grammes',
            monthlyGHelper: 'Entre 1 et 400',
            faceImage: 'Autoportrait',
            startCam: 'Démarrer la caméra',
            signature: 'Signature',
            clearSig: 'Effacer',
            confirmSig: 'Confirmer la signature',
            memberquest: 'Questions sur le membre',
            resident: 'Je réside en Espagne',
            chooseOne: 'Sélectionnez une des options',
            habitual: 'Je consomme régulièrement du cannabis',
            medical: 'Je suis un patient du cannabis médical',
            tos: 'Conditions générales',
            showTos: 'Voir les CG (AS. MILENA VERDA - Solicitud de ingreso de socio)',
            tosChecked: 'J\'ai lu et j\'accepte les conditions générales. Je veux devenir membre.',
            createMember: 'Créer le membre',
            createdLbl: 'Nouveau membre créé ✅',
            regThankyou: 'Merci de vous inscrire. Vous êtes maintenant prêt à vous inscrire, adressez-vous à notre personnel.',
            regPersonalId: 'Votre ID de membre :',
            regPersonalQR: 'Votre code QR personnel :',
            regPersonalQRExplain: 'Le code QR a également été envoyé à votre adresse électronique. Conservez-le ou faites une capture d\'écran maintenant afin de pouvoir vous enregistrer facilement la prochaine fois.',
            continueMsg: 'Pour continuer, assurez-vous que tous les champs sont bien remplis',
            emailMissing: 'Email manquant',
            fistNameMissing: 'Prénom manquant',
            lastNameMissing: 'Nom manquant',
            addressMissing: 'Adresse manquante',
            docNumberMissing: 'Numéro du document manquant',
            docMissing: 'Sorte de document manquant',
            monthlyCons: 'Consommation mensuelle manquante',
            acceptTos: 'Acceptez les conditions générales',
            signatureMissing: 'Signature manquante',
            faceImageMissing: 'Autoportrait manquant'
          }                 
        }
      },
      de: {
        translation: {
          seo: {
            title: '▷ Cannabis Social Club Barcelona | Eixample CSC',
            meta: 'Garden Barcelona, der exklusive ✓Cannabis Social Club im Zentrum von Barcelona. Designinterieur, ♫Musik & ☀Veranstaltungen. ➔Jetzt Mitglied werden!'
          },
          btns: {
            appointment: 'Termin buchen',
            maps: 'Auf Maps finden',
            instagram: 'Kontaktieren auf Instagram',
            telegram: 'Kontaktieren auf Telegram',
            whatsappChat: 'Chatten auf Whatsapp'
          },
          home: {
            name: 'Garden Social Club',
            intro: 'Garden Barcelona ist ein Premium Cannabis Social Club, gegründet in Kooperation mit der deutschen Firma Hanf im Glück und befindet sich im Herzen von Barcelonas Eixample Viertel. Dank seiner zentralen Lage lässt sich der private Cannabis Club bequem mit den öffentlichen Verkehrsmitteln, dem eigenen Auto oder auch zu Fuß erreichen.\n\nGarden Barcelona überzeugt seine Mitglieder dabei nicht nur mit einem ansprechenden wie durchdachten Interieur Design, sondern auch mit interessanten Fachveranstaltungen und großartigen Social Events. Das professionell geschulte Team führt die Gäste mit Vergnügen und ausreichend Zeit durch das exklusive wie hochwertige Menü und macht es den Mitgliedern extrem einfach, sich rundum wohlzufühlen.',
            hlWhat: 'Was wir tun',
            what: 'Wir sind davon überzeugt, dass Cannabis die Lebensqualität der Menschen auf vielfältige Weise verbessern kann. Daher betrachten wir es als unsere Mission, durch begleiteten Zugang zu handverlesenen Premium Produkten und konsequente Aufklärung zu einem verantwortungsvollen Umgang mit Cannabis beizutragen.\n\nGleichzeitig möchten wir im Garden Social Club aber auch die unzähligen Möglichkeiten der Cannabispflanze als Ressource, Genussmittel, Arznei und Wirtschaftstreiber erkenntlich machen, um so für eine Veränderung im Mindset der breiten Masse zu sorgen.\n\nDas direkte Feedback unserer Mitglieder bildet dabei die Basis unserer Arbeit, die wir fortlaufend optimieren möchten.',
            hlWhatWeOffer: 'Was wir anbieten',
            whatWeOffer: 'Cannabis ist unsere Leidenschaft und diese wollen wir mit unseren Mitgliedern teilen. Daher setzen wir in allen Bereichen des Garden Cannabis Social Clubs auf allerhöchste Qualität. Dies gilt sowohl für die Gestaltung unseres hochwertigen Sortiments, das sich durch Frische, Varianz und Exklusivität hervortut, als auch für die Beratungsleistung unseres Teams, die ausführlich und individuell über Produkte, Inhaltsstoffe, Konsumformen und mögliche Auswirkungen von THC-haltigen Produkten informieren soll.\n\nWir wollen für unsere Mitglieder nicht nur zum Cannabis Social Club des Vertrauens werden, sondern sie durch die Vermittlung von Fachwissen dazu befähigen, einen eigenen Beitrag zu mehr Aufklärung, Sicherheit und Selbstbestimmung im Zusammenhang mit Cannabis zu leisten.',
            hlFacilities: 'Einrichtung',
            facilities: 'Unsere Mitglieder haben nur das Beste verdient. Daher denken wir, dass unsere serviceorientierte Beratung zu hochwertigen Cannabisprodukten auch in einem erstklassigen Ambiente erfolgen sollte. Um diesem Anspruch gerecht zu werden, haben wir viel Wert auf die Konzeption eines ansprechenden wie durchdachten Interior-Designs gelegt.\n\nDas Ergebnis dieser Bemühungen ist ein Social Club, der sich nicht nur durch die Auswahl von Beleuchtung und Musik an Anlass und Atmosphäre des jeweiligen Tages anpasst, sondern einfach jeder Bedarfslage seiner Mitglieder gerecht werden kann: Egal ob Co-Working-Session mit Kollegen, Spieleabend mit Freunden, Billard-Turnier mit neuen Bekanntschaften oder Fachvortrag im Kreise interessierter Club-Mitglieder - unser Club richtet sich stets nach euch!',
            hlMusic: 'Musik & Events',
            music: 'Garden Social Club ist ein Ort der Begegnung. Denn der soziale Aspekt eines Cannabisclubs sollte unserer Meinung nach genauso wichtig sein wie eine angenehme Atmosphäre oder professionelle Beratung zu exklusiven Produkten. Daher bieten wir unseren Mitgliedern eine Vielzahl an durchdachten Events an, die neben Cannabis auch Musik, Kunst, Mode oder Well-Being in den Mittelpunkt des jeweiligen Abends stellen.\n\nDurch sorgfältig ausgewählte Kooperationen mit Künstlern, Marken und Fachleuten versuchen wir jedes Event mit einem Mehrwert für unsere Mitglieder zu versehen. Mit Hilfe eines abwechslungsreichen Angebots an Live-Musik, Entertainment und Gruppenaktivitäten möchten wir die verschiedenen Geschmäcker unserer Gäste nicht nur ansprechen, sondern sie unter dem gemeinsamen Nenner Cannabis miteinander vereinen und erweitern.',
            hlLocation: 'Lage',
            location: 'Die zentrale Lage im Herzen des wunderschönen Eixample Viertels ist zweifellos einer der größten Vorteile des Garden Cannabis Social Club. In der Nähe des bekannten Pg. de Sant Joan und direkt zwischen den U-Bahn-Stationen Verdaguer und Girona gelegen, ist der Garden CSC leicht zu erreichen und stellt seinen Mitgliedern gleichzeitig eines der schönsten Viertel der Stadt Barcelona vor.',
            time: 'Unser Cannabis Social Club ist das ganze Jahr über, an jedem Tag der Woche geöffnet. Somit sind die Räumlichkeiten des Clubs für unsere Mitglieder jeden Tag zwischen 17 Uhr und Mitternacht verfügbar.'
          },
          memberCreate: {
            title1: 'Willkommen bei',
            title2: 'Garden CSC',
            titleExplain: 'Bitte füllen Sie die neuen Mitgliedsdaten aus',
            firstName: 'Vorname',
            lastName: 'Nachname',
            address: 'Adresse',
            addressHelper: 'Straße, Hausnummer, PLZ, Ort',
            ref: 'Empfohlen von Mitglied',
            refHelper: 'Der Name oder Nummer eines bestehenden Mitglieds',
            birthday: 'Geburtstag',
            docType: 'Dokument',
            docType1: 'Reisepass',
            docType2: 'Ausweißkarte',
            docType3: 'Führerschein',
            docNumber: 'Dokumentennummer',
            monthlyG: 'Monatliche Verbrauchsschätzung in Gramm',
            monthlyGHelper: 'Zwischen 1 und 400g',
            faceImage: 'Selfie',
            startCam: 'Kamera starten',
            signature: 'Unterschrift',
            clearSig: 'Löschen',
            confirmSig: 'Unterschrift bestätigen',
            memberquest: 'Mitgliederfragen',
            resident: 'Ich bin in Spanien wohnhaft',
            chooseOne: 'Was trifft auf dich zu?',
            habitual: 'Ich bin ein gewohnheitsmäßiger Cannabiskonsument',
            medical: 'Ich bin ein Patient mit medizinischem Cannabis',
            tos: 'Allgemeine Geschäftsbedingungen',
            showTos: 'Anzeigen (AS. MILENA VERDA - Solicitud de ingreso de socio)',
            tosChecked: 'Ich habe die AGB gelesen und akzeptiere sie. Ich möchte Mitglied werden.',
            createMember: 'Mitglied erstellen',
            createdLbl: 'Neues Mitglied erstellt ✅',
            regThankyou: 'Danke für die Registrierung. Gehe jetzt zum Check-in und spreche mit unseren Mitarbeitern',
            regPersonalId: 'Ihre persönliche Mitglieds-ID:',
            regPersonalQR: 'Dein persönlicher QR Code:',
            regPersonalQRExplain: 'Der QR Code wurde auch an deine E-Mail Adresse geschickt. Bewahre diese E-Mail sicher auf oder erstelle jetzt einen Screenshot, denn bei jedem Besuch wirst du deinen QR Code benötigen.',
            continueMsg: 'Um fortzufahren, bitte alle Felder ausfüllen',
            emailMissing: 'E-Mail fehlt',
            fistNameMissing: 'Vorname fehlt',
            lastNameMissing: 'Nachname fehlt',
            addressMissing: 'Adresse fehlt',
            docNumberMissing: 'Dokumentnummer fehlt',
            docMissing: 'Dokumententyp fehlt',
            monthlyCons: 'Monatlicher Verbrauch fehlt',
            acceptTos: 'Geschäftsbedingungen akzeptieren',
            signatureMissing: 'Unterschrift fehlt',
            faceImageMissing: 'Selfie fehlt'
          }
        }
      },
      es: {
        translation: {
          seo: {
            title: '▷ Cannabis Social Club Barcelona | Eixample CSC',
            meta: 'Garden Barcelona, el exclusivo ✓Club Social de Cannabis en el centro de Barcelona. Interiores de diseño, buena ♫música y ☀eventos. ➔¡Hazte miembro!'
          },
          btns: {
            appointment: 'Reservar una cita',
            maps: 'Buscar en mapas',
            instagram: 'Contacto en Instagram',
            telegram: 'Contacto en Telegram',
            whatsappChat: 'Chatear en Whatsapp'
          },
          home: {
            name: 'Garden Social Club',
            intro: 'Garden Barcelona es un exclusivo club social de cannabis fundado en colaboración con la empresa alemana Hanf im Glück y situado en el corazón del Eixample de Barcelona. Su céntrica ubicación permite llegar fácilmente en transporte público, en coche o a pie.\n\nGarden Barcelona deleita a sus socios no solo con un atractivo y cuidado interiorismo, sino también con interesantes encuentros profesionales, música seleccionada y magníficos eventos sociales. Nuestros socios reciben un trato y asesoramiento excelentes gracias a un equipo formado profesionalmente que les guía a través de un menú excepcional de alta calidad, facilitando que todos los miembros se sientan muy a gusto y acompañados.',
            hlWhat: 'Qué hacemos',
            what: 'Estamos convencidos de que el cannabis puede mejorar de muchas maneras la calidad de vida de las personas. Por ello, consideramos que nuestra misión es contribuir en su uso responsable mediante una educación consecuente y un acceso guiado a productos de primera calidad seleccionados cuidadosamente.\n\nAl mismo tiempo, en Garden Social Club también queremos hacer reconocible el inmenso potencial de esta planta como recurso, estimulante, medicina y motor económico, con el fin de propiciar un cambio en la mentalidad de las masas. Los comentarios y opiniones que recibimos directamente de nuestros miembros constituyen la base de nuestro trabajo, que intentamos optimizar cada día.',
            hlWhatWeOffer: 'Qué ofrecemos',
            whatWeOffer: 'El cannabis es nuestra pasión y queremos compartirla con nuestros socios. Así pues, apostamos por la máxima calidad en todos los ámbitos del Garden Cannabis Social Club. Esto se aplica tanto al planteamiento de nuestro surtido de alta calidad, que destaca por su frescura, variedad y exclusividad, como al servicio de asesoramiento de nuestro equipo, cuyo objetivo es proporcionar información detallada e individualizada sobre productos, ingredientes, formas de consumo y posibles efectos de los productos que contienen THC.\n\nNo solo queremos convertirnos en el club de confianza de nuestros miembros; mediante la transmisión de conocimientos también queremos fomentar su propia contribución a una mayor educación, seguridad y autodeterminación en relación con el cannabis.',
            hlFacilities: 'Instalaciones',
            facilities: 'Nuestros miembros se merecen lo mejor: creemos que nuestro servicio orientado en el asesoramiento sobre productos de cannabis de alta calidad también debe tener lugar en un ambiente de primera clase. Para cumplir esta intención, hemos puesto mucho énfasis en la concepción de un diseño interior atractivo y meditado.\n\nEl resultado de estos esfuerzos es un club social que se adapta a diversas ocasiones y momentos del día mediante una cuidadosa selección de iluminación y música, siendo capaz de satisfacer todas las necesidades de sus socios: ya sea una sesión de co-working con colegas, una tarde de juegos con amigos, un torneo de billar con nuevos conocidos o una charla especializada en compañía de socios interesados. ¡Nuestro club siempre está a vuestra disposición!',
            hlMusic: 'Música y eventos',
            music: 'Garden Social Club es un lugar de reunión, porque, en nuestra opinión, el aspecto social de un club cannábico debería ser tan importante como tener un ambiente agradable o brindar un asesoramiento profesional sobre productos exclusivos. Por eso ofrecemos a nuestros socios una variedad de eventos originales que, además de en el cannabis, también se centran en la música, el arte, la moda o el bienestar según la velada.\n\nA través de selectas colaboraciones con artistas, marcas y profesionales, en cada evento intentamos aportar un valor añadido a nuestros miembros. A través de una variada oferta de música en directo, entretenimiento y actividades en grupo, pretendemos no sólo apelar a los diferentes gustos de nuestros huéspedes, sino aunarlos y expandirlos bajo el denominador común del cannabis.',
            hlLocation: 'Ubicación',
            location: 'Su céntrica ubicación en el corazón del hermoso barrio del Eixample es sin duda una de las mayores ventajas del Garden Cannabis Social Club. Situado cerca del famoso Pg. de Sant Joan y entre las estaciones de metro de Verdaguer y Girona, el Garden es de fácil acceso y, al mismo tiempo, introduce a sus miembros a uno de los barrios más bellos de la ciudad de Barcelona.',
            time: 'Nuestro club está abierto todo el año y cada día de la semana. Por lo tanto, nuestras instalaciones están a disposición de los socios todos los días entre las 17:00 y las 00:00 horas.'
          },
          memberCreate: {
            title1: 'Bienvenido al',
            title2: 'Garden CSC',
            titleExplain: 'Rellena los detalles del nuevo socio',
            firstName: 'Nombre',
            lastName: 'Apellidos',
            address: 'Dirección',
            addressHelper: 'Calle, número, piso y puerta / Código postal, cuidad',
            ref: 'Avalado por',
            refHelper: 'Nombre del socio avalador',
            birthday: 'Fecha de nacimiento',
            docType: 'Tipo de documento',
            docType1: 'Pasaporte',
            docType2: 'DNI/NIE',
            docType3: 'Permiso de conducir',
            docNumber: 'Número de documento',
            monthlyG: 'Consumo mensual estimado en gramos',
            monthlyGHelper: 'Entre 1 y 400',
            faceImage: 'Selfie',
            startCam: 'Iniciar la cámara',
            signature: 'Firma',
            clearSig: 'Borrar',
            confirmSig: 'Confirmar firma',
            memberquest: 'Preguntas sobre el socio',
            resident: 'Soy residente en España',
            chooseOne: 'Escoje una opción',
            habitual: 'Soy consumidor habitual de cannabis',
            medical: 'Soy paciente de cannabis medicinal',
            tos: 'Términos y condiciones',
            showTos: 'Mostrar TyC (AS. MILENA VERDA - Solicitud de ingreso de socio)',
            tosChecked: 'He leído y acepto los términos y condiciones. Quiero ser miembro.',
            createMember: 'Crear socio',
            createdLbl: 'Se ha creado el nuevo miembro ✅',
            regThankyou: 'Gracias por registrarte. Ya puedes acceder al club, dirígete al mostrador para hablar con nuestro personal.',
            regPersonalId: 'Tu número de miembro es:',
            regPersonalQR: 'Tu código QR:',
            regPersonalQRExplain: 'El código QR también se ha enviado a tu dirección de correo electrónico. Guárdalo o haz una captura de pantalla ahora para poder hacer el check in fácilmente la próxima vez.',
            continueMsg: 'Para continuar, asegúrate de que todos los campos están completos',
            emailMissing: 'Falta el email',
            fistNameMissing: 'Falta el nombre',
            lastNameMissing: 'Falta el apellido',
            addressMissing: 'Falta la dirección',
            docNumberMissing: 'Falta el número de documento',
            docMissing: 'Falta el tipo de documento',
            monthlyCons: 'Falta el consumo mensual',
            acceptTos: 'Aceptar términos y condiciones',
            signatureMissing: 'Falta la firma',
            faceImageMissing: 'Falta la selfie'
          }
        }
      },
      cat: {
        translation: {
          seo: {
            title: '▷ Cannabis Social Club Barcelona | Eixample CSC',
            meta: 'Garden Barcelona, l\'exclusiu ✓Club Social de Cànnabis al centre de Barcelona. Interiors de diseny, bona ♫música i ☀esdeveniments. ➔Fes-te soci!'
          },
          btns: {
            appointment: 'Reserva cita',
            maps: 'Cerca a Maps',
            instagram: 'Contacta a Instagram',
            telegram: 'Contacta a Telegram',
            whatsappChat: 'Xateja per Whatsapp'
          },
          home: {
            name: 'Garden Social Club',
            intro: 'Garden Barcelona és un exclusiu club social de cànnabis fundat en col·laboració amb l\'empresa alemanya Hanf im Glück i situat en el cor de l\'Eixample de Barcelona. La seva cèntrica ubicació permet arribar fàcilment en transport públic, amb cotxe o a peu.\n\nGarden Barcelona delecta als seus socis no només amb un atractiu i cuidat interiorisme, sinó també amb interessants trobades professionals, música seleccionada i magnífics esdeveniments socials. Els nostres socis reben un tracte i assessorament excel·lents gràcies a un equip format professionalment que els guia a través d\'un menú excepcional d\'alta qualitat, facilitant que tots els membres se sentin ben a gust i acompanyats.',
            hlWhat: 'Què fem',
            what: 'Estem convençuts que el cànnabis pot millorar de moltes maneres la qualitat de vida de les persones. Per això, considerem que la nostra missió és contribuir en el seu ús responsable mitjançant una educació conseqüent i un accés guiat a productes de primera qualitat seleccionats acuradament.\n\nAl mateix temps, a Garden Social Club també volem fer recognoscible l\'immens potencial d\'aquesta planta com a recurs, estimulant, medicina i motor econòmic, amb la finalitat de propiciar un canvi en la mentalitat de les masses. Els comentaris i opinions que rebem directament dels nostres membres constitueixen la base del nostre treball, que intentem optimitzar cada dia.',
            hlWhatWeOffer: 'Què oferim',
            whatWeOffer: 'El cànnabis és la nostra passió i volem compartir-la amb els nostres socis. Així doncs, apostem per la màxima qualitat en tots els àmbits del Garden Cannabis Social Club. Això s\'aplica tant al plantejament del nostre assortiment d\'alta qualitat, que destaca per la seva frescor, varietat i exclusivitat, com al servei d\'assessorament del nostre equip, l\'objectiu del qual és proporcionar informació detallada i individualitzada sobre productes, ingredients, formes de consum i possibles efectes dels productes que contenen THC.\n\nNo sols volem convertir-nos en el club de confiança dels nostres membres; mitjançant la transmissió de coneixements també volem fomentar la seva pròpia contribució a una major educació, seguretat i autodeterminació en relació amb el cànnabis.',
            hlFacilities: 'Instal·lacions',
            facilities: 'Els nostres membres es mereixen el millor: creiem que el nostre servei orientat en l\'assessorament sobre productes de cànnabis d\'alta qualitat també ha de tenir lloc en un ambient de primera classe. Per complir aquesta intenció, hem posat molt d\'èmfasi en la concepció d\'un disseny interior atractiu i meditat.\n\nEl resultat d\'aquests esforços és un club social que s\'adapta a diverses ocasions i moments del dia mitjançant una acurada selecció d\'il·luminació i música, sent capaç de satisfer totes les necessitats dels seus socis: ja sigui una sessió de co-working amb col·legues, una tarda de jocs amb amics, un torneig de billar amb nous coneguts o una xerrada especialitzada en companyia de socis interessats. El nostre club sempre està a la vostra disposició!',
            hlMusic: 'Música i esdeveniments',
            music: 'Garden Social Club és un lloc de reunió, perquè, en la nostra opinió, l\'aspecte social d\'un club cannàbic hauria de ser tan important com tenir un ambient agradable o brindar un assessorament professional sobre productes exclusius. Per això oferim als nostres socis una varietat d\'esdeveniments originals que, a més d\'en el cànnabis, també se centren en la música, l\'art, la moda o el benestar segons la vetllada.\n\nA través de selectes col·laboracions amb artistes, marques i professionals, a cada esdeveniment intentem aportar un valor afegit als nostres membres. A través d\'una variada oferta de música en directe, entreteniment i activitats en grup, pretenem no només apel·lar als diferents gustos dels nostres hostes, sinó unir-los i expandir-los sota el denominador comú del cànnabis.',
            hlLocation: 'Ubicació',
            location: 'La seva cèntrica ubicació en el cor del meravellós barri de l\'Eixample és sens dubte un dels majors avantatges del Garden Cannabis Social Club. Situat prop del famós Pg. de Sant Joan i entre les estacions de metro de Verdaguer i Girona, el Garden és de fàcil accés i, al mateix temps, introdueix als seus membres a un dels barris més bells de la ciutat de Barcelona.',
            time: 'El nostre club està obert tot l\'any i cada dia de la setmana. Per tant, les nostres instal·lacions estan a la disposició dels socis tots els dies entre les 17:00 i les 00:00 hores.'
          },
          memberCreate: {
            title1: 'Benvingut al',
            title2: 'Garden CSC',
            titleExplain: 'Completa les dades del nou soci',
            firstName: 'Nom',
            lastName: 'Cognoms',
            address: 'Adreça',
            addressHelper: 'Carrer, número, pis i porta / Codi postal, ciutat',
            ref: 'Avalat per',
            refHelper: 'Nom del soci avalador',
            birthday: 'Data de naixement',
            docType: 'Tipus de document',
            docType1: 'Passaport',
            docType2: 'DNI/NIE',
            docType3: 'Permís de conduir',
            docNumber: 'Número del document',
            monthlyG: 'Consum mensual estimat en grams',
            monthlyGHelper: 'Entre 1 i 400',
            faceImage: 'Selfie',
            startCam: 'Inicia la càmera',
            signature: 'Signatura',
            clearSig: 'Esborra',
            confirmSig: 'Confirma la signatura',
            memberquest: 'Preguntes sobre el soci',
            resident: 'Soc resident a Espanya',
            chooseOne: 'Tria una opció',
            habitual: 'Soc consumidor habitual de cannabis',
            medical: 'Soc pacient de cannabis medicinal',
            tos: 'Termes i condicions',
            showTos: 'Mostra els TiC (AS. MILENA VERDA - Solicitut d\'ingrés de soci)',
            tosChecked: 'He llegit i accepto els termes i condicions. Vull ser membre.',
            createMember: 'Crea el soci',
            createdLbl: 'S\'ha creat el nou membre ✅',
            regThankyou: 'Gràcies per registrar-te. Ja pots accedir al club, ves al taulell per parlar amb el nostre personal.',
            regPersonalId: 'El teu número de membre és:',
            regPersonalQR: 'El teu codi QR:',
            regPersonalQRExplain: 'El codi QR també s\'ha enviat a la teva adreça de correu electrònic. Desa\'l o fes una captura de pantalla ara per poder fer el check in fàcilment el próxim cop.',
            continueMsg: 'Per continuar, assegura\'t que tots els camps estan complets',
            emailMissing: 'Falta l\'email',
            fistNameMissing: 'Falta el nom',
            lastNameMissing: 'Falta el cognom',
            addressMissing: 'Falta l\'adreça',
            docNumberMissing: 'Falta el número de document',
            docMissing: 'Falta el tipus de document',
            monthlyCons: 'Falta el consum mensual',
            acceptTos: 'Acceptar termes i condicions',
            signatureMissing: 'Falta la signatura',
            faceImageMissing: 'Falta la selfie'
          }
        }
      }
    }
  });

document.documentElement.lang = i18n.language

export default i18n
