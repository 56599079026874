import packageJson from '../package.json'
const d = {
  isDev: process.env.NODE_ENV === 'development'
}

Object.assign(d, {
  version: packageJson.version,
  apiUrl: 'https://api.gardenbarcelona.es/v1',
  qrCdnUrl: 'https://qrcdn.gardenbarcelona.es/qr/',
  homeUrl: 'https://gardenbarcelona.es',
  mapsUrl: 'https://g.page/r/CTIlJk0nMit8EAE',
  calendlyUrl: 'https://calendly.com/gardencsc/new-member-application',
  instagramUrl: 'https://instagram.com/gardenbarcelona',
  telegramUrl: 'https://t.me/gardencscNews',
  whatsappUrl: 'https://api.whatsapp.com/send?phone=34669727113',
  cdn: undefined,
  lstMax: 50,
  maxName: 35,
  maxLow: 25,
  maxElip: 5,
  maxHigh: 100,
  minPw: 8,
  maxPw: 36,
  tsYearFormat: 'YYYY-MM-DD',
  tsDateFormat: 'YYYY-MM-DD HH:mm',
  tsDateFormatDetail: 'YYYY-MM-DD HH:mm:ss',
  tsFormat: 'HH:mm',
  tsFormatDetail: 'HH:mm:ss',
  dfltLocale: 'ES',
  membershipMonthValid: 6,
  langs: [{
    code: 'en',
    name: 'English'
  },{
    code: 'es',
    name: 'Spanish'
  }, {
    code: 'cat',
    name: 'Catalan'
  }, {
    code: 'de',
    name: 'German'
  },{
    code: 'fr',
    name: 'French'
  },{
    code: 'it',
    name: 'Italian'
  }]
})

const cfg = key => (key == null ? d : d[key])
export default cfg
