import React from 'react'
import {
  Box,
  Paper,
  TextField,
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
  FormControlLabel,
  FormGroup,
  Select,
  Checkbox,
  Button
} from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import * as mo from 'moment'
import { withStyles } from '@mui/styles'
import QRCode from 'react-qr-code'
import Camera from 'react-html5-camera-photo'
import 'react-html5-camera-photo/build/css/index.css'
import SignatureCanvas from 'react-signature-canvas'
import { Trans } from 'react-i18next'
import i18n from '../i18n'
import { TopBar, Snack, ModalCust, TosContract, LngSelect } from './Lib'
import {
  styleGuide,
  gridItem,
  gridSpacer,
  paperStyle,
  sigCanvas,
  qrCodeWrap
} from './Style'
import __ from '../util'

class MemberCreate extends React.Component {
  constructor (props) {
    super(props)
    this.cx = props.cx
    this.widthType = props.width
    this.reset = {
      camFaceActive: false,
      faceImageDataUri: undefined,
      faceImageId: undefined,
      signatureImageId: undefined,
      isCreateActive: false,
      tosChecked: false,
      showTos: false,
      residentChecked: false,
      habitualChecked: false,
      medicalChecked: false,
      idMember: undefined,
      emailEmsg: undefined,
      signatureDataUri: undefined,
      email: '',
      firstName: '',
      lastName: '',
      address: '',
      ref: '',
      docType: '',
      birthday: '2000-01-01',
      docNumber: '',
      monthlyG: '',
      isComplete: false,
      sigPadTrimmedDataURL: undefined,
      isLoading: false,
      newLang: undefined
    }
    this.state = {
      ...this.reset
    }
    this.handleTakePhoto = this.handleTakePhoto.bind(this)
    this.handleDocTypeChange = this.handleDocTypeChange.bind(this)
    this.handleCreateMember = this.handleCreateMember.bind(this)
    this.handleConfirmSig = this.handleConfirmSig.bind(this)
    this.sigPad = {}

  }

  async handleTakePhoto (type, imageData) {
    this.setState({
      camFaceActive: false
    })
    const imageId = await this.cx.core.uploadDocument(type, imageData)
    // const imageId = await this.cx.member.uploadDocument(type, imageData)
    this.setState({
      faceImageDataUri: imageData,
      faceImageId: imageId
    })
  }

  handleDocTypeChange(event) {
    this.setState({
      docType: event.target.value
    });
  }

  async handleConfirmSig () {
    const isEmpty = this.sigPad.isEmpty()

    if(isEmpty) {
      this.setState({
        snack: 'Signature is empty'
      })
    } else {
      const signatureImageId = await this.cx.core.uploadDocument('signature', this.sigPad.getTrimmedCanvas().toDataURL())

      // disable pad
      this.sigPad.off()

      this.setState({
        signatureImageId,
        snack: 'Signature confirmed',
        signatureDataUri: this.sigPad.getTrimmedCanvas().toDataURL()
      })
    }
  }

  async handleCreateMember () {
    this.setState({isLoading: true})

    const idMember = await this.cx.core.createMember(
      this.state.email,
      this.state.firstName,
      this.state.lastName,
      this.state.address,
      this.state.ref,
      this.state.birthday,
      this.state.docType,
      this.state.docNumber,
      this.state.monthlyG,
      this.state.residentChecked,
      this.state.habitualChecked,
      this.state.medicalChecked,
      this.state.docImageId,
      this.state.faceImageId,
      this.state.signatureImageId,
      i18n.resolvedLanguage
    )

    this.setState({
      // set modal to show new member id to write down
      idMember,
      isComplete: true,
      isLoading: false
    })
  }

  set (ilk, val) {
    this.setState({ [ilk]: val, emsg: undefined }, () => {
      const d = {}

      d.emailEmsg = __.vldEmail(this.state.email)

      this.setState(d)
    })
  }

  async componentDidMount () {
    // set body bg
    document.body.style.backgroundColor = styleGuide.backgroundDark

    Object.assign(this, __.initView(this, 'memberCreate'))
  }

  render () {
    if (this.state.err) {
      return (
        <ModalCust>
          {this.state.err}
        </ModalCust>
      )
    } else if (this.state.isComplete) {
      return (
        <ModalCust
          noCncl
          lbl={<Trans i18nKey='memberCreate.createdLbl'>New member created ✅</Trans>}
          actions={[
            {
              lbl: 'Close',
              onClick: () => window.location.reload()
            },
            {
              lbl: 'Save',
              onClick: () => {
                const oldTitle = document.title
  
                document.title = `QR Code Member ${this.state.idMember}`
  
                window.print()
  
                document.title = oldTitle

                /*
                const a = document.createElement('a')
                a.href = `${__.cfg('qrCdnUrl')}${this.state.idMember}.png`
                a.download = `${this.state.idMember}.png`
                a.click()
                */
              }
            }
          ]}
        >
          <Typography variant='body1' component='span' gutterBottom>
            <Trans i18nKey='memberCreate.regThankyou'>Thank you for registering. You are ready to check-in, please talk to our staff.</Trans>
          </Typography><br /><br />
          <Typography variant='body1' component='span'>
            <Trans i18nKey='memberCreate.regPersonalId'>Your personal member ID:</Trans>
          </Typography><br />
          <Typography variant='h4' component='span' style={{fontFamily:'sutro'}}>{this.state.idMember}</Typography><br />
          <Typography variant='body1' component='span'>
            <Trans i18nKey='memberCreate.regPersonalQR'>Your QR code:</Trans>
          </Typography><br />
          <div className={this.props.classes.qrCodeWrap}>
            <QRCode 
              size={256}
              value={'grdn|'+this.state.idMember} 
              style={{display:'block', width: '100%'}} 
            />
          </div>
          <Typography variant='body1' component='span'>
            <Trans i18nKey='memberCreate.regPersonalQRExplain'>The QR code was also sent to your email address. Keep this safe so that you can easily check in next time or take a screenshot now.</Trans>
          </Typography>
        </ModalCust>
      )
    } else if (this.state.showTos) {
      return (
        <Paper elevation={0} square>
          <TopBar
            noUser
            noCart
            noSchedule
            noUpdate
            noBackoffice
            noDash
            noAlert
            noMemberCreate
            noFinance
            iconLeft={<ArrowBack />}
            onClickLeft={(event) => {
              this.setState({
                showTos: false
              })
              window.scrollTo(0, 0)
            }}
            midTitle={'TOS'}
          />
          <Paper className={this.props.classes.paperStyle} square>
            <TosContract
              docNumber={this.state.docNumber}
              lastName={this.state.lastName}
              firstName={this.state.firstName}
              address={this.state.address}
              email={this.state.email}
              birthday={this.state.birthday}
              monthlyG={this.state.monthlyG}
              // residentChecked={this.state.residentChecked}
              signatureDataUri={this.state.signatureDataUri}
              habitualChecked={this.state.habitualChecked}
              medicalChecked={this.state.medicalChecked}
              dateToday={mo().format(__.cfg('tsYearFormat'))}
            />
            <Button onClick={(event) => {
              this.setState({
                showTos: false
              })
              window.scrollTo(0, 0)
            }} style={{marginTop:40}}>
              Back
            </Button>
          </Paper>
        </Paper>
      )
    } else {
      return (
        <div>
          {this.state.snack && (
            <Snack
              msg={this.state.snack}
              onClose={() => this.setState({ snack: null })}
            />
          )}
          <Paper elevation={0} square>
            <div className={this.props.classes.paperStyle}>
              <LngSelect
                value={i18n.resolvedLanguage}
                onChange={(event) => {
                  i18n.changeLanguage(event.target.value)
                  this.setState({newLang: event.target.value})
                }}
              />
              <img
                height='130px'
                width='130px'
                src='//qrcdn.gardenbarcelona.es/img/higlogo-trans.png'
                title='Logo Garden CSC'
                alt='Logo Garden CSC'
                style={{margin: '0 auto', display:'block', marginTop: 20}}
                loading='lazy'
              />
              <Typography variant='h4' align='center' style={{margin: '75px 0', fontFamily: 'sutro'}}>
                <Trans i18nKey='memberCreate.title1'>
                  Welcome to
                </Trans>
                <br />
                <nobr>
                  <Trans i18nKey='memberCreate.title2'>
                    Garden CSC
                  </Trans>
                </nobr>
              </Typography>
              <Typography variant='subtitle1'>
                <Trans i18nKey='memberCreate.titleExplain'>
                  Please fill new member details
                </Trans>
              </Typography>

              <TextField
                fullWidth
                label='Email'
                margin='normal'
                value={this.state.email}
                inputProps={{ inputMode: 'email' }}
                error={
                  Boolean(this.state.emsg) || Boolean(this.state.emailEmsg)
                }
                helperText={this.state.emsg || this.state.emailEmsg}
                onChange={(event) => {
                  this.set('email', event.target.value)
                }}
              />

              <TextField
                fullWidth
                label={<Trans i18nKey='memberCreate.firstName'>First Name</Trans>}
                margin='normal'
                value={this.state.firstName}
                onChange={(event) => {
                  this.setState({
                    firstName: event.target.value
                  })
                }}
              />

              <TextField
                fullWidth
                label={<Trans i18nKey='memberCreate.lastName'>Last Name</Trans>}
                margin='normal'
                value={this.state.lastName}
                onChange={(event) => {
                  this.setState({
                    lastName: event.target.value
                  })
                }}
              />

              <TextField
                fullWidth
                label={<Trans i18nKey='memberCreate.address'>Address</Trans>}
                margin='normal'
                helperText={<Trans i18nKey='memberCreate.addressHelper'>Street + Number, Floor / Apt, Zip, City, Country</Trans>}
                value={this.state.address}
                onChange={(event) => {
                  this.setState({
                    address: event.target.value
                  })
                }}
              />

              <TextField
                fullWidth
                label={<Trans i18nKey='memberCreate.ref'>Referred by</Trans>}
                margin='normal'
                helperText={<Trans i18nKey='memberCreate.refHelper'>The name of a existing member referred you</Trans>}
                value={this.state.ref}
                onChange={(event) => {
                  this.setState({
                    ref: event.target.value
                  })
                }}
              />

              <TextField
                fullWidth
                id='birthday'
                label={<Trans i18nKey='memberCreate.birthday'>Birthday</Trans>}
                type='date'
                margin='normal'
                value={this.state.birthday}
                onChange={(event) => {
                  this.setState({
                    birthday: event.target.value
                  })
                }}
              />

              <FormControl margin='normal' fullWidth>
                <InputLabel id='document-type-selector'>
                  <Trans i18nKey='memberCreate.docType'>Document Type</Trans>
                </InputLabel>
                <Select
                  labelId='document-type-selector'
                  id='document-type'
                  value={this.state.docType}
                  label={<Trans i18nKey='memberCreate.docType'>Document Type</Trans>}
                  onChange={this.handleDocTypeChange}
                >
                  <MenuItem value={1}>
                    <Trans i18nKey='memberCreate.docType1'>Passport</Trans>
                  </MenuItem>
                  <MenuItem value={2}>
                    <Trans i18nKey='memberCreate.docType2'>ID-Card</Trans>
                  </MenuItem>
                  <MenuItem value={3}>
                    <Trans i18nKey='memberCreate.docType3'>Drivers-Licence</Trans>
                  </MenuItem>
                </Select>
              </FormControl>

              <TextField
                fullWidth
                label={<Trans i18nKey='memberCreate.docNumber'>Document Number</Trans>}
                margin='normal'
                value={this.state.docNumber}
                onChange={(event) => {
                  this.setState({
                    docNumber: event.target.value
                  })
                }}
              />

              <TextField
                fullWidth
                label={<Trans i18nKey='memberCreate.monthlyG'>Monthly estimate of consumption in gramm</Trans>}
                margin='normal'
                value={this.state.monthlyG}
                inputProps={{ inputMode: 'number' }}
                helperText={<Trans i18nKey='memberCreate.monthlyGHelper'>Between 1 and 400</Trans>}
                onChange={(event) => {
                  const numberOnly = event.target.value.replace(/\D/g,'')
                  if((Number.isInteger(parseInt(numberOnly)) && parseInt(numberOnly) !== 0 && parseInt(numberOnly) <= 400) || event.target.value === '') {
                    this.setState({
                      monthlyG: numberOnly
                    })
                  }
                }}
              />
              {(this.state.email.length < 4 || this.state.firstName.length < 3 || this.state.lastName.length < 3 || this.state.address.length < 4 || this.state.docNumber.length < 4 || this.state.emailEmsg.length > 0 || this.state.docType === '' || this.state.monthlyG === '') && <Typography variant='h5' style={{marginTop:40, fontFamily: 'sutro'}} gutterBottom>
                <Trans i18nKey='memberCreate.continueMsg'>To continue please ensure all fields are complete</Trans>:
              </Typography> }
              {(this.state.email.length < 4) && <Typography>
                - <Trans i18nKey='memberCreate.emailMissing'>Email missing</Trans>
              </Typography> }
              {(this.state.firstName.length < 3) && <Typography>
                - <Trans i18nKey='memberCreate.fistNameMissing'>First name missing</Trans>
              </Typography> }
              {(this.state.lastName.length < 3) && <Typography>
                - <Trans i18nKey='memberCreate.lastNameMissing'>Last name missing</Trans>
              </Typography> }
              {(this.state.address.length < 4 ) && <Typography>
                - <Trans i18nKey='memberCreate.addressMissing'>Address missing</Trans>
              </Typography> }
              {(this.state.docNumber.length < 4) && <Typography>
                - <Trans i18nKey='memberCreate.docNumberMissing'>Document number missing</Trans>
              </Typography> }
              {(this.state.docType === '') && <Typography>
                - <Trans i18nKey='memberCreate.docMissing'>Document type missing</Trans>
              </Typography> }
              {(this.state.monthlyG === '') && <Typography>
                - <Trans i18nKey='memberCreate.monthlyCons'>Monthly consumption missing</Trans>
              </Typography> }

              <Typography variant='h5' style={{marginTop:40, fontFamily: 'sutro'}} gutterBottom>
                <Trans i18nKey='memberCreate.faceImage'>Face Image</Trans>
              </Typography>
              {!this.state.camFaceActive && <Button
                fullWidth
                variant='outlined'
                color='primary'
                disabled={this.state.email.length < 4 || this.state.firstName.length < 3 || this.state.lastName.length < 3 || this.state.address.length < 4 || this.state.docNumber.length < 4 || this.state.emailEmsg.length > 0 || this.state.docType === '' || this.state.monthlyG === '' }
                onClick={() => {
                  this.setState({camFaceActive: true})
                }}
              >
                <Trans i18nKey='memberCreate.startCam'>Start Cam</Trans>
              </Button>}
              {this.state.camFaceActive && <div>
                <Camera
                  onTakePhoto={(faceImageDataUri) => { this.handleTakePhoto('face', faceImageDataUri) }}
                  idealFacingMode='user'
                  idealResolution={{width: 640, height: 480}}
                  // isImageMirror={false}
                  imageType='jpg'
                  imageCompression={0.90}
                />
              </div>}
              {this.state.faceImageDataUri && this.state.faceImageDataUri.length > 0 && <Box style={{textAlign: 'center', marginTop: 8}}><img alt='img' style={{ maxWidth: '100%', maxHeight: '420px' }} src={this.state.faceImageDataUri} /></Box>}

              <Typography variant='h5' style={{marginTop:40, fontFamily: 'sutro'}} gutterBottom>
                <Trans i18nKey='memberCreate.signature'>Signature</Trans>
              </Typography>
              <Box style={{textAlign: 'center', position: 'relative'}}>
                {!this.state.signatureDataUri && <SignatureCanvas penColor='green' canvasProps={{className: this.props.classes.sigCanvas}} ref={(ref) => { this.sigPad = ref }} />}
                {this.state.signatureDataUri && <img alt='signature' height='150px' width='318px' src={this.state.signatureDataUri} style={{maxWidth:'100%', maxHeight:'185px', marginTop: 18, marginBottom: 18}}/>}
                <Button
                  onClick={() => {
                    this.sigPad.clear()
                  }}
                  disabled={this.state.signatureImageId !== undefined}
                  style={{position:'absolute', top: 0, right: 0}}
                >
                  <Trans i18nKey='memberCreate.clearSig'>Clear</Trans>
                </Button>
                <Button
                  fullWidth
                  variant='outlined'
                  color='primary'
                  onClick={this.handleConfirmSig}
                  disabled={this.state.signatureImageId !== undefined}
                  style={{marginTop: 8}}
                >
                  <Trans i18nKey='memberCreate.confirmSig'>Confirm Signature</Trans>
                </Button>
              </Box>

              <Typography variant='h5' style={{marginTop:40, fontFamily: 'sutro'}} gutterBottom>
                <Trans i18nKey='memberCreate.memberquest'>Member Questions</Trans>
              </Typography>
              <FormControl component='fieldset'>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox name='residentChecked' checked={this.state.residentChecked} onChange={(event) => {
                      this.setState({
                        residentChecked: event.target.checked
                      })
                    }} />}
                    label={<Trans i18nKey='memberCreate.resident'>I am a resident in Spain</Trans>}
                  />
                  <Typography variant='h5' style={{marginTop:40, fontFamily: 'sutro'}} gutterBottom>
                    <Trans i18nKey='memberCreate.chooseOne'>Choose one</Trans>
                  </Typography>
                  <FormControlLabel
                    control={<Checkbox name='habitualChecked' checked={this.state.habitualChecked} disabled={this.state.medicalChecked} onChange={(event) => {
                      this.setState({
                        habitualChecked: event.target.checked,
                        medicalChecked: false
                      })
                    }} />}
                    label={<Trans i18nKey='memberCreate.habitual'>I am a habitual cannabis user</Trans>}
                  />
                  <FormControlLabel
                    control={<Checkbox name='medicalChecked' checked={this.state.medicalChecked} disabled={this.state.habitualChecked} onChange={(event) => {
                      this.setState({
                        habitualChecked: false,
                        medicalChecked: event.target.checked
                      })
                    }} />}
                    label={<Trans i18nKey='memberCreate.medical'>I am a medical cannabis patient</Trans>}
                  />
                </FormGroup>
              </FormControl>

              <Typography variant='h5' style={{marginTop:40, fontFamily: 'sutro'}} gutterBottom>
                <Trans i18nKey='memberCreate.tos'>Terms & Conditions</Trans>
              </Typography>
              <Button
                fullWidth
                variant='outlined'
                color='primary'
                onClick={(event) => {
                  this.setState({
                    showTos: true
                  })
                  window.scrollTo(0, 0)
                }}
                style={{marginBottom: 8}}
              ><Trans i18nKey='memberCreate.showTos'>Show TOS (AS. MILENA VERDA - Solicitud de ingreso de socio)</Trans></Button>
              <FormControl component='fieldset'>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox name='tosChecked' checked={this.state.tosChecked} disabled={this.state.signatureImageId === undefined || (!this.state.habitualChecked && !this.state.medicalChecked)} onChange={(event) => {
                      this.setState({
                        tosChecked: event.target.checked
                      })
                    }} />}
                    label={<Trans i18nKey='memberCreate.tosChecked'>I have read and I accept the terms and conditions. I want to become a member.</Trans>}
                  />
                </FormGroup>
              </FormControl>

              {!this.state.isLoading && (!this.state.tosChecked || this.state.signatureImageId === undefined || this.state.faceImageId === undefined) && <Typography variant='h5' style={{marginTop:40, fontFamily: 'sutro'}} gutterBottom>
                <Trans i18nKey='memberCreate.continueMsg'>To continue please ensure all fields are complete</Trans>:
              </Typography> }
              {(!this.state.tosChecked) && <Typography>
                - <Trans i18nKey='memberCreate.acceptTos'>Accept terms & conditions</Trans>
              </Typography> }
              {(this.state.signatureImageId === undefined) && <Typography>
                - <Trans i18nKey='memberCreate.signatureMissing'>Signature missing</Trans>
              </Typography> }
              {(this.state.faceImageId === undefined) && <Typography>
                - <Trans i18nKey='memberCreate.faceImageMissing'>Face image missing</Trans>
              </Typography> }

              <Button
                fullWidth
                variant='contained'
                color='primary'
                disabled={!this.state.tosChecked || this.state.signatureImageId === undefined || this.state.faceImageId === undefined || this.state.isLoading}
                onClick={this.handleCreateMember}
                style={{marginTop: 40}}
              >
                {<Trans i18nKey='memberCreate.createMember'>Create Member</Trans>}
              </Button>
            </div>
          </Paper>
        </div>
      )
    }
  }
}

export default
  withStyles({
    gridItem,
    gridSpacer,
    paperStyle,
    sigCanvas,
    qrCodeWrap
  })(MemberCreate)
