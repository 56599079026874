import React, { useState, useEffect, useMemo } from 'react';
import { createRoot } from 'react-dom/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { BrowserRouter, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { theme } from './view/Style';
import Core from './logic/Core';
import MemberCreateView from './view/MemberCreate';
import ReferralView from './view/Referral';
import HomeView from './view/Home';
import DrinkMenuView from './view/DrinkMenu';
import i18n from './i18n';

const supportedLanguages = ['de', 'es', 'fr', 'it', 'cat', 'en'];

const AppRoutes = () => {
  const location = useLocation();
  const myLang = supportedLanguages.find((lang) => location.pathname === `/${lang}`) || i18n.language;

  const [lang, setLang] = useState(myLang);
/*  localStorage.setItem('i18nextLng', myLang)
  console.log('myLang', myLang);
*/
  const cx = useMemo(() => {
    return { core: new Core() };
  }, []);

  useEffect(() => {
    const myLang = supportedLanguages.find((lang) => location.pathname === `/${lang}`) || i18n.language;
    setLang(myLang);
    document.documentElement.lang = myLang;
    i18n.changeLanguage(myLang);
    document.title = i18n.t('seo.title');
    document.querySelector('meta[name="description"]').setAttribute('content', i18n.t('seo.meta'));
    document.querySelector('link[rel="canonical"]').setAttribute('href', `https://gardenbarcelona.es/${myLang}`);

  }, [lang, location.pathname]);

  return (
    <MuiThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<HomeView cx={cx} />} />
        {supportedLanguages.map((lang) => (
          <Route key={lang} path={`/${lang}`} element={<HomeView cx={cx} />} />
        ))}
        <Route path="/member/create" element={<MemberCreateView cx={cx} />} />
        <Route path="/menu/drink" element={<DrinkMenuView cx={cx} />} />
        <Route path="/ref" element={<ReferralView cx={cx} />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </MuiThemeProvider>
  );
};

createRoot(document.getElementById('root')).render(
  <BrowserRouter>
    <AppRoutes />
  </BrowserRouter>
);

serviceWorkerRegistration.register();
