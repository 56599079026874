import { createTheme } from '@mui/material/styles'
import common from '@mui/material/colors/common'

const styleGuide = {
  backgroundDark: '#121212',
  text: '#121212',
  textSecondary: '#757575',
  spacertop: '80px'
}

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#c7a17a',
      dark: '#c7a17a'
    },
    secondary: {
      main: '#838383',
      dark: '#838383'
    }
  },
  typography: {
    fontFamily: 'calluna-sans',
    h1: {
      fontFamily: 'proxima-nova',
      fontSize: '4rem',
      fontWeight: 900,
      letterSpacing: '1.5px'
    },
    h2: {
      fontFamily: 'proxima-nova',
      fontSize: '3rem',
      fontWeight: 900,
      letterSpacing: '1.5px'
    },
    h3: {
      fontFamily: 'proxima-nova',
      fontSize: '3rem',
      fontWeight: 900,
      letterSpacing: '1.5px'
    },
    h4: {
      fontFamily: 'proxima-nova',
      fontSize: '2.5rem',
      fontWeight: 900,
      letterSpacing: '1.5px'
    },
    h5: {
      fontFamily: 'proxima-nova',
      fontSize: '2.2rem',
      fontWeight: 900,
      letterSpacing: '1.5px'
    },
    h6: {
      fontFamily: 'proxima-nova',
      fontSize: '2rem',
      fontWeight: 900,
      letterSpacing: '1.5px'
    }
  }
})

theme.components = {
  MuiButton: {
    styleOverrides: {
      containedPrimary: {
        background: 'linear-gradient(45deg, #c7a17a 80%, #fff 20%)',
        borderRadius: 3,
        border: 0,
        color: 'white',
        height: 48,
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(255, 255, 255, .3)'
      }
    }
  },
  MuiInput: {
    underline: {
      '&:after': {
        borderBottom: `2px solid #06d6a0`,
      }
    }
  },
  MuiFormLabel: {
    root: {
      '&$focused': {
        color: '#06d6a0'
      }
    }
  }
}

const loginStyle = {
  paddingTop: styleGuide.spacertop,
  paddingBottom: theme.spacing(2),
  color: common.white
}

const paperStyle = {
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2)
}

const dividerStyle = {
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(4)
}

const noTxtDeco = {
  textDecoration: 'none'
}

const extLink = {
  color: styleGuide.text
}

const fullWidth = {
  width: '100%'
}

const gridWrapPaper = {
  margin: '24px'
}

const gridItem = {
  marginBottom: theme.spacing(),
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  padding: theme.spacing(3),
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
}

const gridSpacer = {
  paddingTop: theme.spacing(),
  paddingBottom: theme.spacing()
}

const lockIcon = {
  width: theme.spacing(2),
  height: theme.spacing(2)
}

const colorFormat = {
  display: 'block',
  height: '100%',
  textAlign: 'center'
}

const gridWrap = {
  flewGrow: 1
}

const dashTable = {
  marginBottom: theme.spacing(3)
}

const sigCanvas = {
  border: '1px solid #525252',
  borderRadius: 4,
  display: 'block',
  width: '100%',
  maxWidth: '400px',
  height: 180,
  margin: '0 auto'
}

const higBotDivider = {
  marginTop: '80px',
  display: 'block',
  width: '100%'
}

const higTopDivider = {
  display: 'block',
  width: '100%',
  marginBottom: '80px'
}

const drinkMenuHeader = {
  fontFamily: 'sutro !important',
  margin: '80px 0 40px 0 !important'
}

const imgClub = {
  maxWidth: '100%',
  display: 'block',
  paddingBottom: 8
}

const imgHome = {
  maxWidth: '100%',
  display: 'block',
  borderRadius: 6
}

const homeHeadlineSpacer = {
  fontFamily: 'sutro !important',
  lineHeight: '1 !important'
}

const homeSpacer = {
  marginBottom: '60px !important',
  marginTop: '60px !important'
}

const homeText = {
  whiteSpace: 'pre-line'
}

const whatsappChat = {
  marginBottom: '20px !important'
}

const qrCodeWrap = {
  background: 'white',
  padding: 16
}

const homeBg = {
  backgroundColor: 'rgb(18, 18, 18) !important',
  backgroundImage: 'url("//qrcdn.gardenbarcelona.es/img/garden-home-bg-2.jpg") !important',
  backgroundSize: 'contain',
  backgroundPosition: 'top center',
  backgroundRepeat: 'no-repeat',
  paddingBottom: 60, 
  paddingTop: 30,
  [theme.breakpoints.down('sm')]: {
    paddingLeft: 8,
    paddingRight: 8
  },
  [theme.breakpoints.up('lg')]: {
    paddingTop: '230px !important'
  }
}

export {
  styleGuide,
  theme,
  loginStyle,
  paperStyle,
  dividerStyle,
  noTxtDeco,
  extLink,
  fullWidth,
  gridWrapPaper,
  gridItem,
  gridSpacer,
  lockIcon,
  colorFormat,
  gridWrap,
  dashTable,
  sigCanvas,
  higBotDivider,
  higTopDivider,
  drinkMenuHeader,
  imgClub,
  imgHome,
  homeHeadlineSpacer,
  homeSpacer,
  homeText,
  whatsappChat,
  qrCodeWrap,
  homeBg
}
