import React from 'react'
import {
  Paper,
  Typography,
  Button,
  LinearProgress,
  Grid
} from '@mui/material'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import InstagramIcon from '@mui/icons-material/Instagram'
import { withStyles } from '@mui/styles'
import { Snack, ModalCust } from './Lib'
import {
  styleGuide,
  gridItem,
  gridSpacer,
  paperStyle,
  drinkMenuHeader
} from './Style'
import __ from '../util'

class DrinkMenu extends React.Component {
  constructor (props) {
    super(props)
    this.cx = props.cx
    this.reset = {
      isLoading: true,
      menu: undefined
    }
    this.state = {
      ...this.reset
    }
    this.handleWhatsappShare = this.handleWhatsappShare.bind(this)
    this.getDrinkMenu = this.getDrinkMenu.bind(this)
  }

  handleWhatsappShare () {
    window.open('whatsapp://send?text=Garden CSC drinks menu: https://gardenbarcelona.es/menu/drink', '_blank')
  }

  async getDrinkMenu () {
    let menu

    this.setState({
      isLoading: true
    })

    try {
      [menu] = await Promise.all([
        this.cx.core.getDrinkMenu()
      ])
    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ err: emsg })
      throw e
    }
    if(Array.isArray(menu) && menu.length > 0) {
      this.setState({
        menuDrinks: menu[0],
        menuSnacks: menu[1],
        isLoading: false
      })
    } else {
      this.props.history.push('/ref')
    }
  }

  async componentDidMount () {
    // set body bg
    document.body.style.backgroundColor = styleGuide.backgroundDark

    Object.assign(this, __.initView(this, 'drinkmenu'))

    await this.getDrinkMenu()
  }


  render () {
    if (this.state.err) {
      return (
        <ModalCust>
          {this.state.err}
        </ModalCust>
      )
    } else if (this.state.isLoading) {
      return (<LinearProgress />)
    } else if(this.state.menuDrinks && this.state.menuSnacks) {
      return (
        <div>
          {this.state.snack && (
            <Snack
              msg={this.state.snack}
              onClose={() => this.setState({ snack: null })}
            />
          )}
          <Paper square className={this.props.classes.paperStyle} style={{paddingBottom: 60, paddingTop: 20}} elevation={0}>
            <Grid container spacing={0} justifyContent='center'>
              <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
                <img
                  height='130px'
                  width='130px'
                  src='//qrcdn.gardenbarcelona.es/img/higlogo-trans.png'
                  title='Logo Garden CSC'
                  alt='Logo Garden CSC'
                  style={{margin: '0 auto', display:'block'}}
                  loading='lazy'
                />
                <Typography variant='h4' align='center' gutterBottom className={this.props.classes.drinkMenuHeader}>Drinks</Typography>
                {this.state.menuDrinks.map((item) => <div key={item.idItem} style={{marginBottom: 20}}>
                  <Typography variant='body1' align='center' className={this.props.classes.drinkMenuItem}>{item.name}  {__.formatNumber(item.cost)}€</Typography>
                </div>)}

                <Typography variant='h4' align='center' gutterBottom className={this.props.classes.drinkMenuHeader}>Snacks</Typography>
                {this.state.menuSnacks.map((item) => <div key={item.idItem} style={{marginBottom: 20}}>
                  <Typography variant='body1' align='center' className={this.props.classes.drinkMenuItem}>{item.name}  {__.formatNumber(item.cost)}€</Typography>
                </div>)}

                <Button
                  fullWidth
                  onClick={this.handleWhatsappShare}
                  startIcon={<WhatsAppIcon />}
                  style={{marginTop: 60}}
                  >Whatsapp Share</Button>

                <Button
                  fullWidth
                  onClick={this.handleIg}
                  startIcon={<InstagramIcon />}
                  style={{marginTop: 20}}
                  >Contact on Instagram</Button>
              </Grid>
            </Grid>
          </Paper>
        </div>
      )
    } else {
      return (<LinearProgress />)
    }
  }
}

export default
  withStyles({
    gridItem,
    gridSpacer,
    paperStyle,
    drinkMenuHeader
  })(DrinkMenu)
