import React from 'react'
import {
  Paper,
  Typography,
  Button,
  LinearProgress,
  TextField,
  Grid
} from '@mui/material'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import TodayIcon from '@mui/icons-material/Today'
import MapIcon from '@mui/icons-material/Room'
import InstagramIcon from '@mui/icons-material/Instagram'
import { withStyles } from '@mui/styles'
import { Snack, ModalCust } from './Lib'
import {
  styleGuide,
  gridItem,
  gridSpacer,
  paperStyle,
  imgClub
} from './Style'
import __ from '../util'

class Referral extends React.Component {
  constructor (props) {
    super(props)
    this.cx = props.cx
    this.reset = {
      isLoading: false,
      code: '',
      inputDisabled: false,
      showLoc: false
    }
    this.state = {
      ...this.reset
    }
    this.handleRefCode = this.handleRefCode.bind(this)
    this.handleMaps = this.handleMaps.bind(this)
    this.handleAppointment = this.handleAppointment.bind(this)
    this.handleWhatsappShare = this.handleWhatsappShare.bind(this)
    this.handleIg = this.handleIg.bind(this)

    this.unlockCode = '420'
  }

  handleRefCode () {
    this.setState({
      showLoc: true
    })
  }

  handleMaps () {
    window.open(__.cfg('mapsUrl'), '_blank')
  }

  handleAppointment () {
    window.open(__.cfg('calendlyUrl'), '_blank')
  }

  handleWhatsappShare () {
    window.open('whatsapp://send?text=Check out Garden CSC: https://gardenbarcelona.es/ref', '_blank')
  }

  handleIg () {
    window.open('https://instagram.com/gardenbarcelona', '_blank')
  }

  async componentDidMount () {
    // set body bg
    document.body.style.backgroundColor = styleGuide.backgroundDark

    Object.assign(this, __.initView(this, 'referral'))
  }


  render () {
    if (this.state.err) {
      return (
        <ModalCust>
          {this.state.err}
        </ModalCust>
      )
    } else if (this.state.isLoading) {
      return (<LinearProgress />)
    } else if (this.state.showLoc) {
      return (
        <Paper square className={this.props.classes.paperStyle} style={{paddingBottom: 60, paddingTop: 60}} elevation={0}>
          <Grid container spacing={0} justifyContent='center'>
            <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
              <img
                height='130px'
                width='130px'
                src='//qrcdn.gardenbarcelona.es/img/higlogo-trans.png'
                title='Logo Garden CSC'
                alt='Logo Garden CSC'
                style={{margin: '0 auto', display:'block', marginBottom: 60}}
              />
              <Typography variant='h4' align='center' gutterBottom style={{fontFamily: 'sutro'}}>Garden CSC</Typography>
              <Typography variant='body1' align='center' gutterBottom>17:00 - 24:00 daily</Typography>
              <Typography variant='body1' align='center' gutterBottom style={{marginBottom: 75}}>C/ de Bailèn, 110, 08009 Barcelona, Spain</Typography>
              <Button
                fullWidth
                variant='contained'
                color='primary'
                onClick={this.handleMaps}
                style={{marginBottom: 20}}
                startIcon={<MapIcon />}
                >Find on Maps</Button>
              <Button
                fullWidth
                variant='contained'
                color='primary'
                onClick={this.handleAppointment}
                style={{marginBottom: 20}}
                startIcon={<TodayIcon />}
                >Book appointment</Button>
              <Button
                fullWidth
                variant='contained'
                color='primary'
                onClick={this.handleWhatsappShare}
                style={{marginBottom: 20}}
                startIcon={<WhatsAppIcon />}
                >Whatsapp Share</Button>
              <Button
                fullWidth
                variant='contained'
                color='primary'
                onClick={this.handleIg}
                startIcon={<InstagramIcon />}
                >Contact on Instagram</Button>
            </Grid>
          </Grid>
          <Grid container spacing={1} justifyContent='center' style={{marginTop: 75}}>
            <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
              <img src='//qrcdn.gardenbarcelona.es/img/Garden-CSC-Lounge.jpg' alt='Lounge' className={this.props.classes.imgClub}/>
              <img src='//qrcdn.gardenbarcelona.es/img/Garden-CSC-Desk-Member.jpg' alt='Desk Member' className={this.props.classes.imgClub}/>
              <img src='//qrcdn.gardenbarcelona.es/img/Garden-CSC-Room-1-Social.jpg' alt='Room 1 Social' className={this.props.classes.imgClub}/>
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
              <img src='//qrcdn.gardenbarcelona.es/img/Garden-CSC-Lounge-3.jpg' alt='Lounge' className={this.props.classes.imgClub}/>
              <img src='//qrcdn.gardenbarcelona.es/img/Garden-CSC-Merch.jpg' alt='Merch' className={this.props.classes.imgClub}/>
              <img src='//qrcdn.gardenbarcelona.es/img/Garden-CSC-Room-1-Board-Games.jpg' alt='Room 1 Board Games' className={this.props.classes.imgClub}/>
            </Grid>
          </Grid>
        </Paper>
      )
    } else {
      return (
        <div>
          {this.state.snack && (
            <Snack
              msg={this.state.snack}
              onClose={() => this.setState({ snack: null })}
            />
          )}
          <Paper square className={this.props.classes.paperStyle} style={{paddingBottom: 60, paddingTop: 60}} elevation={0}>
            <Grid container spacing={0} justifyContent='center'>
              <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
                <img
                  height='130px'
                  width='130px'
                  src='//qrcdn.gardenbarcelona.es/img/higlogo-trans.png'
                  title='Logo Garden CSC'
                  alt='Logo Garden CSC'
                  style={{margin: '0 auto', display:'block', marginBottom: 60}}
                />
                <Typography variant='h4' align='center' gutterBottom style={{fontFamily: 'sutro',  marginBottom: 60}}>Enter the Garden</Typography>

                <TextField
                  fullWidth
                  label='Code'
                  margin='normal'
                  value={this.state.code}
                  disabled={this.state.inputDisabled}
                  onChange={(event) => {
                    this.setState({
                      code: event.target.value,
                      inputDisabled: event.target.value === this.unlockCode
                      // snack: event.target.value === this.unlockCode ? 'Welcome to the Club 🎉' : undefined
                    })
                  }}
                />

                <Button
                  fullWidth
                  variant='contained'
                  color='primary'
                  disabled={this.state.code === '' || this.state.code !== this.unlockCode}
                  onClick={this.handleRefCode}
                >
                  🌴🌳🌲🌿
                </Button>
                <Button
                  fullWidth
                  onClick={this.handleIg}
                  startIcon={<InstagramIcon />}
                  style={{marginTop: 60}}
                  >Contact on Instagram</Button>
              </Grid>
            </Grid>
          </Paper>
        </div>
      )
    }
  }
}

export default
  withStyles({
    gridItem,
    gridSpacer,
    paperStyle,
    imgClub
  })(Referral)
