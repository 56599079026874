import { StoBase } from './Lib'
import __ from '../util'

export default class Core extends StoBase {
  constructor (cx) {
    super('core', cx, 'b4c5c3c6-8e55-4397-a99c-5f0cfda1fdcd')
    this._store = 'core'
    this.isActive = () => Boolean(this.getSto())
    this.clear = this.clear.bind(this)
    this.init = this.init.bind(this)
    this.get = this.get.bind(this)
    this.getDrinkMenu = this.getDrinkMenu.bind(this)
    this.uploadDocument = this.uploadDocument.bind(this)
    this.createMember = this.createMember.bind(this)
  }

  clear () {
    const cx = this.cx
    __.clearObj(cx)
    cx.core = new Core(cx)
    __.clearSto()
  }

  get (key) {
    const core = this.getSto() || {}
    const val = core[key]
    if (val === undefined) {
      this.clear()
      throw this.err(`Getting core data "${key}" failed`, { sts: 900 })
    }
    return val
  }

  init () {

    const core = this.getSto()

    if (!core) return false

    return true
  }

  async uploadDocument (type, imagedata) {
    let pld
    try {
      pld = await this.rqst({
        url: 'member/document',
        method: 'post',
        data: { type, imagedata }
      })
    } catch (e) {
      let emsg, sts
      /*if (e.sts === 404) {
        emsg = 'Invalid login/password combination'
        sts = 404
      } else if (e.sts >= 400 && e.sts < 500) {
        emsg = 'Invalid login/password combination'
        sts = 400
      } else {
        emsg = 'Login failed temporary: Please try again later'
        sts = e.sts
      }*/
      throw this.err(emsg, { e, sts })
    }
    return pld
  }

  async getDrinkMenu () {
    let pld
    try {
      pld = await this.rqst({
        url: 'menu/drink'
      })
    } catch (e) {
      let emsg, sts
      throw this.err(emsg, { e, sts })
    }
    return pld
  }

  async createMember (email, firstName, lastName, address, ref, birthday, docType, docNumber, monthlyG, residentChecked, habitualChecked, medicalChecked, docImageId, faceImageId, signatureImageId, language) {
    const createdBy = __.getJsonSto('core') ? __.getJsonSto('core').loginemail : 'publicUser'

    let pld
    try {
      pld = await this.rqst({
        url: 'member',
        method: 'post',
        data: { email, firstName, lastName, address, ref, birthday, docType, docNumber, monthlyG, residentChecked, habitualChecked, medicalChecked, docImageId, faceImageId, signatureImageId, createdBy, language }
      })
    } catch (e) {
      let emsg, sts
      throw this.err(emsg, { e, sts })
    }
    return pld
  }

}
